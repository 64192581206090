const INVALID_DATE = 'Invalid date type was provided'

export const toDate = (dateStr: Backend.Models.DateTime) => dateStr ? new Date(dateStr).toDateString() : INVALID_DATE

export const toTime = (dateStr: Backend.Models.DateTime) => {
  if(!dateStr) return INVALID_DATE
  const dateObject = new Date(dateStr)
  const timeString = dateObject.toLocaleTimeString('en-US')
  const timezoneOffsetInHours = dateObject.getTimezoneOffset() / 60
  return `${timeString} (${hourOffsetToHourMinuteFormat(timezoneOffsetInHours)})`
}

export const hourOffsetToHourMinuteFormat = (hourOffset: number): string => {
  const isNegative = hourOffset < 0
  const absoluteHourOffset = Math.abs(hourOffset)
  const isSingleDigit = absoluteHourOffset < 10
  const hourMinuteString = isSingleDigit ? `0${absoluteHourOffset}` : absoluteHourOffset.toString()
  return `${isNegative ? '+' : '-'}${hourMinuteString}:00`
}

declare global {
  interface Window {
    userTimeZone: string;
  }
}

export const toUserDateTime = (value: Date | string | number, locale: string = 'en-GB') => {
  const date = value instanceof Date ? value : new Date(value)
  return date.toLocaleString(locale, { timeZone: window.userTimeZone || 'Europe/London' })
}

export const toUserDate = (value: Date | string | number, locale: string = 'en-GB', noWeekday = false) => {
  const date = value instanceof Date ? value : new Date(value)
  return date.toLocaleDateString(locale, { weekday: noWeekday ? undefined : 'short', day: '2-digit', month: 'short', year: 'numeric', timeZone: window.userTimeZone || 'Europe/London' })
}

export const toUserTime = (value: Date | string | number, locale: string = 'en-GB') => {
  const date = value instanceof Date ? value : new Date(value)
  return date.toLocaleTimeString(locale, { timeZone: window.userTimeZone || 'Europe/London' })
}

export const getTodayDate = (format: string): string | number => {
  const date = new Date()
  if(format === 'YYYY-MM-DD') {
    return date.toISOString().split('T')[0]
  } else if(format === 'D'){
    return date.getDate()
  } else return date.toISOString()
}

export const ordinalSuffixOf = (i: number) => {
  const j = i % 10,
    k = i % 100;
  if (j == 1 && k != 11) {
    return i + "st";
  }
  if (j == 2 && k != 12) {
    return i + "nd";
  }
  if (j == 3 && k != 13) {
    return i + "rd";
  }
  return i + "th";
}

export const humanTime = (hour: number, minute: number): string => {
  const minutes = minute < 10 ? `0${minute}` : minute
  const hours = hour < 10 ? `0${hour}` : hour
  return `${hour}:${minutes}`
}

export const minutesToMilliseconds = (minutes: number) => minutes * 60 * 1000

export const daysOfMonthWithSuffix = () => {
  const daysOfMonth = []
  for (let i = 1; i <= 31; i++) {
    daysOfMonth.push([ordinalSuffixOf(i), i])
  }
  return daysOfMonth
}
